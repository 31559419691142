import {
  Avatar,
  Box,
  Button,
  Flex,
  FormLabel,
  Heading,
  Icon,
  Image,
  SimpleGrid,
  Tag,
  Text,
  useColorModeValue,
  useMediaQuery,
} from "@chakra-ui/react";
import Usa from "assets/img/dashboards/usa.png";
import { getPieChartData } from "variables/charts";
import Dblue from "assets/img/dashboards/dblue.png";
import Arg from "assets/img/dashboards/arg.png";
import MiniCalendar from "components/calendar/MiniCalendar";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React, { useState, useEffect } from "react";
import { MdCurrencyBitcoin, MdAccountBalance } from "react-icons/md";
import PieCard from "views/admin/default/components/PieCard";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { useHistory } from "react-router-dom";
import URL from "constants/url";
import { useCurrency } from "contexts/CurrencyContext";
import DOLAR_BLUE_LOGO from "../../../assets/img/dolar_blue_icon.png";
import ARGENTINA_ICON from "../../../assets/img/argentina_icon.png";
import USA_ICON from "../../../assets/img/usa_icon.png";
import ModalTermsConditions from "components/Modals/TermsConditions";
import DOLAR_BLUE from "../../../assets/img/DOLAR_BLUE.svg";
import { TIPO_ICONOS } from "constants/iconos";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import DoughnutChart from "components/DonutChart";
import Card from "components/card/Card";
import { FaChartPie } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";

const UserReports = () => {
  const [isUsaSelected, setIsUsaSelected] = useState(false);
  const history = useHistory();
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "secondaryGray.300");
  const [criptoValue, setCriptoValue] = useState(null);
  const [mervalValue, setMervalValue] = useState(null);
  const [accionesValue, setAccionesValue] = useState(null);
  const [totalInversiones, setTotalInversiones] = useState(null);
  const [totalInversionesAnterior, setTotalInversionesAnterior] =
    useState(null);
  const [dolarBlueValue, setDolarBlueValue] = useState(null);
  const [porcentMerval, setPorcentMerval] = useState(0);
  const [porcentCrypto, setPorcentCrypto] = useState(0);
  const [porcentAcciones, setPorcentAcciones] = useState(0);
  const [pieChartData, setPieChartData] = useState([]);
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [userActives, setUserActives] = useState({});
  const [userActivesData, setUserActivesData] = useState([]);
  const [loading, setLoading] = useState(false)

  const { currency } = useCurrency();
  const tipo_valor = currency === "ARS" ? "valor_pesos" : "valor";
  const tipo_valor_anterior =
    currency === "ARS" ? "valor_anterior_pesos" : "valor_anterior";
  const formatter = new Intl.NumberFormat("es-ES", {});
  const [lastUpdate, setLastUpdate] = useState(null);
  const textColor = useColorModeValue("secondaryGray.900", "white");

  const getLastUpdate = async () => {
    try {
      const lastUpdate = await axios.get(`${URL}/api/actives/last_update`);
      setLastUpdate(lastUpdate.data.lastUpdate);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLastUpdate();
  }, []);

  console.log(currency);

  useEffect(() => {
    const token = localStorage.getItem("token");
    console.log(token);

    if (!token) {
      history.push("/auth/iniciar-sesion");
    } else {
      setLoading(true)
      const decodedToken = jwtDecode(token);
      console.log(decodedToken);
      axios
        .get(`${URL}/api/actives/${decodedToken.userId}`)
        .then((resp) => {
          let sumCripto = 0;
          let sumMerval = 0;
          let sumAcciones = 0;

          let sumCriptoAnterior = 0;
          let sumMervalAnterior = 0;
          let sumAccionesAnterior = 0;

          resp.data.Actives.forEach((elem) => {
            console.log(elem);
            if (elem.id_tipo === 1) {
              let aux = elem[tipo_valor] * elem.User_Active.amount;
              sumCripto += aux;
              sumCriptoAnterior +=
                elem[tipo_valor_anterior] * elem.User_Active.amount;
            }
            if (elem.id_tipo === 2) {
              let aux = elem[tipo_valor] * elem.User_Active.amount;
              sumMerval += aux;
              sumMervalAnterior +=
                elem[tipo_valor_anterior] * elem.User_Active.amount;
            }
            if (elem.id_tipo === 3) {
              let aux = elem[tipo_valor] * elem.User_Active.amount;
              sumAcciones += aux;
              sumAccionesAnterior +=
                elem[tipo_valor_anterior] * elem.User_Active.amount;
            }
          });

          // Calcular los porcentajes en función de los valores originales en dólares

          const total = sumCripto + sumMerval + sumAcciones;
          const total_anterior =
            sumCriptoAnterior + sumMervalAnterior + sumAccionesAnterior;

          const porcentajeInversionAnterior =
            ((total - total_anterior) / total_anterior) * 100;

          const totalInversiones =
            currency === "ARS"
              ? `$${formatter.format(
                  (sumCripto + sumMerval + sumAcciones).toFixed(2)
                )}`
              : `US$ ${formatter.format(
                  (sumCripto + sumMerval + sumAcciones).toFixed(2)
                )}`;

          const totalInversionesAnterior =
            currency === "ARS"
              ? `$${formatter.format(
                  (
                    sumCriptoAnterior +
                    sumMervalAnterior +
                    sumAccionesAnterior
                  ).toFixed(2)
                )}`
              : `US$ ${formatter.format(
                  (
                    sumCriptoAnterior +
                    sumMervalAnterior +
                    sumAccionesAnterior
                  ).toFixed(2)
                )}`;

          let porcentajesActivos = resp.data.Actives.map((item) => {
            const porcentaje =
              (item[tipo_valor] * item.User_Active.amount * 100) / total;
            return {
              porcentaje,
              ticker: item.ticker,
              valor: item.valor,
              color: item.color,
              logo: item.logo,
            };
          });
          porcentajesActivos.sort((a, b) => b.porcentaje - a.porcentaje);

          if (porcentajesActivos.length > 0) {
            const data = {
              labels: porcentajesActivos.map(
                (item) => `${item.ticker} (${item.porcentaje.toFixed(2)}%)`
              ),
              datasets: [
                {
                  label: "",
                  data: porcentajesActivos.map((item) => item.porcentaje),
                  backgroundColor: porcentajesActivos.map((item) => item.color),
                  hoverOffset: 4,
                },
              ],
            };
            setUserActives(data);

            if (porcentajesActivos.length > 5) {
              const filtroActivos = porcentajesActivos.filter(
                (_, index) => index >= 5
              );
              const porcentajeOtros = filtroActivos.reduce(
                (acc, value, index) => {
                  acc += value.porcentaje;
                  return acc;
                },
                0
              );
              porcentajesActivos = porcentajesActivos.filter(
                (_, index) => index < 5
              );
              porcentajesActivos.push({
                porcentaje: porcentajeOtros,
                ticker: "Otros",
                valor: null,
                color: "",
                logo: null,
              });
            }

            setUserActivesData(porcentajesActivos);
          }

          const porcentMerval = (sumMerval * 100) / total;
          const porcentCrypto = (sumCripto * 100) / total;
          const porcentAcciones = (sumAcciones * 100) / total;

          console.log(porcentCrypto);

          setCriptoValue(
            currency === "ARS"
              ? `$ ${formatter.format(sumCripto)}`
              : `US$ ${formatter.format(sumCripto.toFixed(2))}`
          );
          setMervalValue(
            currency === "ARS"
              ? `$ ${formatter.format(sumMerval)}`
              : `US$ ${formatter.format(sumMerval.toFixed(2))}`
          );
          setAccionesValue(
            currency === "ARS"
              ? `$ ${formatter.format(sumAcciones)}`
              : `US$ ${formatter.format(sumAcciones.toFixed(2))}`
          );

          // Guardar los valores y porcentajes actualizados
          setTotalInversiones(totalInversiones);
          setTotalInversionesAnterior({
            porcentaje: porcentajeInversionAnterior,
            totalInversionesAnterior: totalInversionesAnterior,
            total: total,
            totalAnterior: total_anterior,
          });

          setPorcentAcciones(porcentAcciones);
          setPorcentMerval(porcentMerval);
          setPorcentCrypto(porcentCrypto);

          // Verificar si los porcentajes son NaN o no están definidos
          isNaN(porcentMerval)
            ? setPorcentMerval(0)
            : setPorcentMerval(porcentMerval);

          isNaN(porcentCrypto)
            ? setPorcentCrypto(0)
            : setPorcentCrypto(porcentCrypto);

          isNaN(porcentAcciones)
            ? setPorcentAcciones(0)
            : setPorcentAcciones(porcentAcciones);

            setLoading(false)

        })


        .catch((error) => {
          console.error("Error fetching actives:", error);
          setLoading(false)

        });
      if (decodedToken && decodedToken?.exp * 1000 < Date.now()) {
        history.push("/auth/iniciar-sesion");
      }
    }
  }, [history, dolarBlueValue, currency]);

  useEffect(() => {
    axios
      .get("https://api.bluelytics.com.ar/v2/latest")
      .then((response) => {
        const blueValue = response.data.blue.value_avg;
        console.log("Dólar Blue Value:", blueValue);
        setDolarBlueValue(blueValue);
      })
      .catch((error) => {
        console.error("Error fetching blue dollar value:", error);
      });
  }, []);

  useEffect(() => {
    setPieChartData([
      Number(porcentMerval.toFixed(2)),
      Number(porcentCrypto.toFixed(2)),
      Number(porcentAcciones.toFixed(2)),
    ]);
  }, [porcentCrypto, porcentMerval, porcentAcciones]);

  return (
    <>
      <ModalTermsConditions />
      <Box
        pt={{ base: "130px", md: "80px", xl: "80px" }}
        mt={isMobile ? "0" : "30px"}
        display="flex"
        flexDirection={"column"}
      >
        {lastUpdate ? (
          <Tag
            variant="subtle"
            color="#4319ff"
            alignSelf={"flex-start"}
            backgroundColor="#efecff"
            fontSize="14px"
            marginBottom="20px"
            width="fit-content"
          >
            Ult. Act. {lastUpdate}hs
          </Tag>
        ) : null}
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 2, "2xl": 6 }}
          gap="20px"
          mb="20px"
        >
          <MiniStatistics
            startContent={
              <Flex
                me="-16px"
                mt="0px"
                alignItems="center"
                transition=" opacity 1s ease 0s"
              >
                <FormLabel htmlFor="balance">
                  <IconBox
                    w="56px"
                    h="56px"
                    bg={boxBg}
                    icon={
                      <Image
                        src={currency === "USD" ? USA_ICON : ARGENTINA_ICON}
                        borderRadius="full"
                      />
                    }
                  />
                </FormLabel>
              </Flex>
            }
            name="Total Inversiones"
            value={totalInversiones}
            data={
              totalInversionesAnterior?.total !== 0
                ? totalInversionesAnterior
                : null
            }
            flex="1"
            maxH="100%"
          />
          <MiniStatistics
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg={boxBg}
                icon={<Image src={DOLAR_BLUE} />}
              />
            }
            name="Valor Dolar Blue"
            value={`$${
              dolarBlueValue !== null
                ? dolarBlueValue.toFixed(2)
                : "Cargando..."
            }`}
          />
        </SimpleGrid>
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
          gap="20px"
          mb="20px"
        >
          <Link to="/admin/gestion-activos?activo=criptomonedas">
            <MiniStatistics
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg={boxBg}
                  icon={<Image src={TIPO_ICONOS[1]} />}
                />
              }
              name="Criptomonedas"
              value={criptoValue}
            />
          </Link>
          <Link to="/admin/gestion-activos?activo=cedears">
            <MiniStatistics
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg={boxBg}
                  icon={<Image src={TIPO_ICONOS[2]} />}
                />
              }
              name="Cedears"
              value={mervalValue}
            />
          </Link>
          <Link to="/admin/gestion-activos?activo=accionesargentinas">
            <MiniStatistics
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg={boxBg}
                  icon={<Image src={TIPO_ICONOS[3]} />}
                />
              }
              name="Acciones"
              value={accionesValue}
            />
          </Link>
        </SimpleGrid>

        <SimpleGrid columns={{ base: 1, md: 2, xl: 1 }} gap="20px" mb="20px">
          <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="0px">
            {/* {porcentCrypto !== Infinity && porcentMerval !== Infinity ? ( */}
              <PieCard
                porcentCrypto={porcentCrypto}
                porcentMerval={porcentMerval}
                porcentAcciones={porcentAcciones}
                pieChartData={pieChartData}
                loading={loading}
              />
            {/* ) : null} */}

            <Card p="20px" align="center" direction="column" w="100%">
              <Flex
                px={{ base: "0px", "2xl": "10px" }}
                justifyContent="space-between"
                alignItems="center"
                w="100%"
                mb="8px"
              >
                <Text color={textColor} fontSize="md" fontWeight="600" mt="4px">
                  Activos totales
                </Text>
              </Flex>
              {/* {Object.keys(userActives).length > 0 ?  */}
                  
              {/* : null} */}

              {!loading && Object.keys(userActives).length === 0
              ?
               <Flex
                  width="100%"
                  justifyContent="center"
                  direction="column"
                  alignItems="center"
                  gap="16px"
                  padding="80px 40px"
                  borderWidth="1px"
                  borderColor="#eaeaea"
                  borderRadius="20px"
                >
                  <Icon fontSize="2xl" color="#4319ff">
                    <FaChartPie />
                  </Icon>
                  <Flex
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    gap="2px"
                  >
                    <Heading size="sm">No se encontraron activos</Heading>
                    <Text size="sm" color="gray.500">
                      Todavía no tenes activos en tu cartera
                    </Text>
                  </Flex>
                  <Link to="/dashboard/gestion-activos">
                    <Button
                      backgroundColor="#4319FF"
                      color="white"
                      width="fit-content"
                      fontSize={"sm"}
                      fontWeight={500}
                      borderRadius={"full"}
                    >
                      Agregar activo
                    </Button>
                  </Link>
                </Flex>
              : 
              <Flex
                    width="100%"
                    justify="space-between"
                    align="flex-start"
                    gap="40px"
                    flexDirection={isMobile ? "column" : "row"}
                  >
                    <Flex width={isMobile ? "100%" : "50%"} flex={"1"}>
                      {!loading && Object.keys(userActives).length > 0 ?
                      <DoughnutChart
                        data={userActives}
                        actives={userActivesData}
                      />
                      : <Skeleton containerClassName="flex-1" width={"265px"} height="265px" borderRadius={"265px"}/> }
                      
                    </Flex>
                    <Flex width={isMobile ? "100%" : "50%"} direction="column" flex={"1"}>
                      {!loading && userActivesData.length > 0 ?

  
                        userActivesData.map((item) => {
                            return (
                              <>
                                <Flex
                                  justify="space-between"
                                  align="center"
                                  borderBottom="1px solid"
                                  borderBottomColor="#eaeaea"
                                  padding="4px 0"
                                >
                                  <Flex align="center" gap="10px">
                                    <Box
                                      width="8px"
                                      height="8px"
                                      backgroundColor={item.color}
                                      borderRadius="100px"
                                    ></Box>
                                    <Text
                                      color="secondaryGray.900"
                                      fontWeight="500"
                                      fontSize="xs"
                                    >
                                      {item.ticker}
                                    </Text>
                                  </Flex>
                                  <Text
                                    color="secondaryGray.900"
                                    fontWeight="700"
                                    fontSize="xs"
                                  >
                                    {item.porcentaje.toFixed(2)}%
                                  </Text>
                                </Flex>
                              </>
                            );
                          })
                        : 
                        <Skeleton count={11} containerClassName="flex-1" width={"200px"} />
                        }
                    </Flex>
                  </Flex>
              }
            </Card>
          </SimpleGrid>
        </SimpleGrid>
      </Box>
    </>
  );
};

export default UserReports;
