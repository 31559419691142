import React, { useState, useRef, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Select,
  Stack,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  extendTheme,
  useColorModeValue,
  ChakraProvider,
  Icon,
  Spinner,
  useToast,
  Box,
  FormLabel,
  Flex,
  Text,
  Avatar,
  Badge,
  Image,
  SelectField, // Importa el ícono de CheckCircle
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { MdQueryStats, Md123, MdArrowBack } from "react-icons/md";
import { FaCheck } from "react-icons/fa";
import axios from "axios";
import DEFAULT_ICON from "../../../../assets/img/default.png";
import URL from "constants/url";
import { useCurrency } from "contexts/CurrencyContext";
import { agregarCriptomonedaReglas } from "utils/formRules";
import { agregarCriptomonedaInitialValues } from "utils/formInitialValues";
import { useForm } from "../../../../hooks/useForm";
import { TIPO_ICONOS } from "constants/iconos";

const theme = extendTheme({
  colors: {
    brand: {
      500: "#4319FF",
      600: "#82FFC9",
    },
  },
});

function ModalAgregarCripto({ isOpen, onClose, actualizar }) {
  const [allActives, setAllActives] = useState(null);

  const { currency } = useCurrency();
  const tipoValor = currency === "ARS" ? "valor_pesos" : "valor";
  const formatter = new Intl.NumberFormat("es-ES", {});

  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const audioRef = useRef(null);
  const [tickerData, setTickerData] = useState(null);
  const [userActive, setUserActive] = useState(null);

  const [crypto, setCrypto] = useState(null);

  /*
  {
    tokenName: "PancakeSwap Token",
    tokenSymbol: "Cake",
    tokenLogo:
      "https://logo.moralis.io/0x38_0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82_27136f1a449a1cd31522b8380ed1531e.png",
    tokenDecimals: "18",
    nativePrice: {
      value: "4096162847981842",
      decimals: 18,
      name: "Binance Chain Native Token",
      symbol: "BNB",
      address: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
    },
    usdPrice: 2.846558796929806,
    usdPriceFormatted: "2.846558796929806153",
    exchangeName: "PancakeSwap v3",
    exchangeAddress: "0x0BFbCF9fa4f9C56B0F40a671Ad40E0805A091865",
    tokenAddress: "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82",
    priceLastChangedAtBlock: "44993101",
    blockTimestamp: "1734560314000",
    possibleSpam: false,
    verifiedContract: true,
    pairAddress: "0xafb2da14056725e3ba3a30dd846b6bbbd7886c56",
    pairTotalLiquidityUsd: "461851.51",
    "24hrPercentChange": "-10.182148783677013",
    securityScore: 95,
  }
  */

  const {
    inputValues,
    handleChange,
    formErrors,
    resetFormErrors,
    handleValidation,
    resetForm,
  } = useForm(
    handleSubmit,
    agregarCriptomonedaReglas,
    agregarCriptomonedaInitialValues
  );

  const CHAINS = [
    {
      label: 'bsc',
      value: '0x38'
    },
    {
      label: 'eth',
      value: '0x1'
    },
    {
      label: 'polygon',
      value: '0x89'
    },
    {
      label: 'avalanche',
      value: '0xa86a'
    },
    {
      label: 'fantom',
      value: '0xfa'
    },
    {
      label: 'palm',
      value: '0x2a15c308d'
    },
    {
      label: 'cronos',
      value: '0x19'
    },
    {
      label: 'arbitrum',
      value: '0xa4b1'
    },
    {
      label: 'gnosis',
      value: '0x64'
    },
    {
      label: 'chiliz',
      value: '0x15b38'
    },
    {
      label: 'base',
      value: '0x2105'
    },
    {
      label: 'optimism',
      value: '0xa'
    },
    {
      label: 'linea',
      value: '0xe708'
    },
    {
      label: 'moonbeam',
      value: '0x504'
    },
    {
      label: 'moonriver',
      value: '0x505'
    },
    {
      label: 'moonbase',
      value: '0x507'
    },
    {
      label: 'flow',
      value: '0x2eb'
    },
    {
      label: 'ronin',
      value: '0x7e4'
    },
    {
      label: 'lisk',
      value: '0x46f'
    }
  ]

  const addCrypto = async () => {
    setIsLoading(true)
    try {
      await axios.post(`${URL}/api/actives/crypto`, { ...crypto, color: inputValues.color, chain: inputValues.chain });
      // Simula una operación asincrónica
      setTimeout(() => {
        setIsLoading(false);
        // Muestra el Toast con el mensaje "Activo Agregado"
        toast({
          position: "bottom",
          duration: 3000,
          isClosable: true,
          render: () => (
            <Box
              color="white"
              p={3}
              bg="#38B6FF"
              borderRadius="md"
              fontWeight="500"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              animate={{ opacity: [0, 1], translateY: [10, 0] }}
            >
              ¡Criptomoneda agregada!
              <Icon
                as={CheckCircleIcon}
                boxSize={5}
                motion={{ type: "spring", stiffness: 400, damping: 25 }}
              />
            </Box>
          ),
        });
        actualizar();
        handleClose();
      }, 2000);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleClose = () => {
    resetForm();
    resetFormErrors();
    onClose();
  };

  async function handleSubmit() {
    setIsLoading(true);
    try {
      const crypto = await axios.get(
        `${URL}/api/actives/crypto/${inputValues.contrato}/${inputValues.chain}`
      );
      console.log(crypto.data);
      setCrypto(crypto.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  }

  useEffect(() => {
    (async () => {
      const actives = await axios.get(`${URL}/api/actives`);
      setAllActives(actives.data);
    })();
  }, []);

  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = useColorModeValue("secondaryGray.600", "white");

  return (
    <ChakraProvider>
      <audio ref={audioRef} src="../sounds/success-sound.mp3" />
      <Modal onClose={handleClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent borderRadius="20px">
          <ModalHeader>Agregar criptomoneda</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {!crypto ? (
              <Stack spacing={4}>
                <Flex direction="column" position="relative">
                  <FormLabel
                    display="flex"
                    //ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={"navy.700"}
                    //mb="8px"
                  >
                    Contrato
                  </FormLabel>
                  <InputGroup>
                    <Input
                      isRequired={true}
                      fontSize="sm"
                      ms={{ base: "0px", md: "0px" }}
                      type="text"
                      placeholder="Contrato de la criptomoneda"
                      //mb="24px"
                      fontWeight="500"
                      size="lg"
                      mt="0px"
                      name="contrato"
                      onChange={handleChange}
                      value={inputValues.contrato}
                    />
                  </InputGroup>
                  {formErrors.contrato ? (
                    <Box>
                      <Text fontSize="14px" color="red.500">
                        {formErrors.contrato}
                      </Text>
                    </Box>
                  ) : null}
                </Flex>
                <Flex direction="column" position="relative">
                  <FormLabel
                    display="flex"
                    //ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={"navy.700"}
                    //mb="8px"
                  >
                    Chain
                  </FormLabel>
                  <InputGroup>
                    <Select
                      isRequired={true}
                      fontSize="sm"
                      ms={{ base: "0px", md: "0px" }}
                      type="text"
                      placeholder="Red"
                      //mb="24px"
                      fontWeight="500"
                      size="lg"
                      mt="0px"
                      name="chain"
                      onChange={handleChange}
                      value={inputValues.chain}
                    >
                      {CHAINS.map((item) => {
                        return <option value={item.value}>{item.label}</option>;
                      })}
                    </Select>
                  </InputGroup>
                  {formErrors.chain ? (
                    <Box>
                      <Text fontSize="14px" color="red.500">
                        {formErrors.chain}
                      </Text>
                    </Box>
                  ) : null}
                </Flex>
              </Stack>
            ) : (
              <>
                <Flex
                  gap="6px"
                  align="center"
                  onClick={() => setCrypto(null)}
                  padding={"0px 0px 10px 0px"}
                >
                  <Icon
                    as={MdArrowBack}
                    color="#4319FF"
                    w="14px"
                    h="14px"
                    fontWeight="2000"
                  />
                  <Text cursor={"pointer"} color="#4319FF" fontWeight="500">
                    Volver a buscar criptomoneda
                  </Text>
                </Flex>
                <Flex
                  direction="row"
                  gap="20px"
                  background="#f6f6f6"
                  borderRadius="20px"
                  padding="10px"
                  justifyContent="space-between"
                >
                  <Flex align="center">
                    <Avatar
                      src={crypto.tokenLogo ? crypto.tokenLogo : DEFAULT_ICON}
                      w="40px"
                      h="40px"
                      me="8px"
                      backgroundColor="white"
                    />
                    <Flex direction="column">
                      <Text color={textColor} fontSize="sm" fontWeight="600">
                        {crypto.tokenSymbol}
                      </Text>
                      <Text
                        color={textColorSecondary}
                        fontSize="sm"
                        fontWeight="400"
                      >
                        {crypto.tokenName}
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex direction="column">
                    <Text fontSize="sm">US$ {crypto.usdPriceFormatted}</Text>
                  </Flex>
                </Flex>
                <Flex direction="column" position="relative">
                  <FormLabel
                    display="flex"
                    //ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={"navy.700"}
                    //mb="8px"
                    marginTop={"6px"}
                  >
                    Color
                  </FormLabel>
                  <InputGroup>
                    <Input
                      isRequired={true}
                      // ms={{ base: "0px", md: "0px" }}
                      type="color"
                      size="lg"
                      mt="0px"
                      name="color"
                      onChange={handleChange}
                      value={inputValues.color}
                      padding={0}
                      border={"none"}
                      borderRadius={"8px"}
                    />
                  </InputGroup>
                  {formErrors.color ? (
                    <Box>
                      <Text fontSize="14px" color="red.500">
                        {formErrors.color}
                      </Text>
                    </Box>
                  ) : null}
                </Flex>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Flex gap="10px" width="100%">
              <Button
                onClick={handleClose}
                variant="outline"
                style={{
                  color: "#4319FF",
                  width: "50%",
                  fontSize: "14px",
                }}
              >
                Cancelar
              </Button>
              {!crypto ? (
                <Button
                  onClick={handleValidation}
                  isDisabled={isLoading}
                  style={{
                    backgroundColor: "#4319FF",
                    color: "white",
                    width: "50%",
                    fontSize: "14px",
                  }}
                >
                  {isLoading ? <Spinner size="sm" color="white" /> : "Buscar"}
                </Button>
              ) : (
                <Button
                  onClick={addCrypto}
                  isDisabled={isLoading}
                  style={{
                    backgroundColor: "#4319FF",
                    color: "white",
                    width: "50%",
                    fontSize: "14px",
                  }}
                >
                  {isLoading ? <Spinner size="sm" color="white" /> : "Agregar"}
                </Button>
              )}
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
}

export default ModalAgregarCripto;
