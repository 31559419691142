export const agregarActivoInitialValues = {
  tickerId: '',
  amount: ''
};

export const editarActivoInitialValues = {
  amount: ''
};

export const agregarCriptomonedaInitialValues = {
  contrato: ''
};

export const editarActivoAdminInitialValues = {
  logo: '',
  color: ''
};