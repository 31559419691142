/* eslint-disable */
import React from "react";
import { Avatar, Icon, Image } from "@chakra-ui/react";
import { MdBarChart, MdPerson, MdHome, MdLock, MdAddTask } from "react-icons/md";
import MainDashboard from "views/admin/default";
import Usuarios from "views/admin/usuarios";
import GestionActivos from "views/admin/gestionactivos";
import Profile from "views/admin/profile";
import DataTables from "views/admin/dataTables";
import RTL from "views/admin/rtl";
import SignInCentered from "views/auth/signIn";
import Register from "views/auth/register";
import Contraseña from "views/auth/contraseña";
import Activos from "views/admin/activos";
import VALORES_MERCADO_ICON from "./assets/img/stats.svg"
import TerminosCondiciones from "views/admin/terminosycondiciones";
import MI_CARTERA_ICON from "./assets/img/dashboard-panel.svg"
import GESTION_DE_CARTERA_ICON from "./assets/img/briefcase.svg"
import AdminActivos from "views/admin/adminActivos";
import { FaBitcoin, FaCoins, FaUser } from "react-icons/fa";

const routes = [
  {
    name: "Mi Cartera",
    layout: "/dashboard",
    path: "/default",
    icon: <Image src={MI_CARTERA_ICON} width="16px" height="16px" color="inherit" />,
    component: MainDashboard,
    hidden: false,
    role: 2
  },
  {
    name: "Gestión de Cartera",
    layout: "/dashboard",
    path: "/gestion-activos",
    icon: <Image src={GESTION_DE_CARTERA_ICON} width="16px" height="16px" color="inherit" />,
    component: GestionActivos,
    secondary: true,
    hidden: false,
    role: 2
  },
  {
    name: "Valores de Mercado",
    layout: "/dashboard",
    path: "/activos",
    icon: <Image src={VALORES_MERCADO_ICON} width="16px" height="16px" color="inherit" bg={"#fff"}/>,
    component: Activos,
    secondary: true,
    hidden: false,
    role: 2
  },
  {
    name: "Mi Perfil",
    layout: "/dashboard",
    path: "/perfil",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="#4319ff" />,
    component: Profile,
    secondary: true,
    hidden: false,
    role: 2
  },
  {
    name: "Iniciar Sesión",
    layout: "/auth",
    path: "/iniciar-sesion",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
    hidden: true,
  },
  {
    name: "Registrarse",
    layout: "/auth",
    path: "/registrarse",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: Register,
    hidden: true,
  },
  {
    name: "Terminos y Condiciones",
    layout: "/dashboard",
    path: "/terminos_condiciones",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: TerminosCondiciones,
    hidden: true,
  },
  {
    name: "Contraseña",
    layout: "/auth",
    path: "/contraseña",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: Contraseña,
    hidden: true,
  },
  {
    name: "Usuarios",
    layout: "/admin",
    path: "/usuarios",
    icon: <Icon as={FaUser} width="20px" height="20px" color="inherit" display={"flex"}/>,
    component: Usuarios,
    hidden: false,
    role: 1
  },
  {
    name: "Activos",
    layout: "/admin",
    path: "/activos",
    icon: <Icon as={FaBitcoin} width="20px" height="20px" color="inherit" display={"flex"} />,
    component: AdminActivos,
    hidden: false,
    role: 1
  },
];

export default routes;
