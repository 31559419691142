// Chakra imports
import {
  Box,
  Flex,
  Text,
  Select,
  useColorModeValue,
  useMediaQuery,
  Icon,
  Heading,
  Button,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import PieChart from "components/charts/PieChart";
import { pieChartOptions } from "variables/charts";
import { VSeparator } from "components/separator/Separator";
import React, { useState } from "react";
import { FaCoins } from "react-icons/fa";
import DoughnutChart from "components/DonutChart";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Skeleton from "react-loading-skeleton";

export default function Conversion(props) {
  const {
    porcentMerval,
    porcentCrypto,
    pieChartData,
    porcentAcciones,
    loading,
    ...rest
  } = props;
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [activos, setActivos] = useState({
    merval: pieChartData[0],
    cryto: pieChartData[1],
  });
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardColor = useColorModeValue("white", "navy.700");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  const activesCompositionData = {
    labels: ["Cedears", "Criptomonedas", "Acciones Argentinas"],
    datasets: [
      {
        label: "",
        data: [
          parseFloat(porcentMerval.toFixed(2)),
          parseFloat(porcentCrypto.toFixed(2)),
          parseFloat(porcentAcciones.toFixed(2)),
        ],
        backgroundColor: ["#422AFB", "#6AD2FF", "#82fec8"],
        hoverOffset: 4,
      },
    ],
  };

  return (
    <Card p="20px" align="center" direction="column" w="100%" {...rest}>
      <Flex
        px={{ base: "0px", "2xl": "10px" }}
        justifyContent="space-between"
        alignItems="center"
        w="100%"
        mb="8px"
      >
        <Text color={textColor} fontSize="md" fontWeight="600" mt="4px">
          Composición de Cartera
        </Text>
      </Flex>

      {/* {pieChartData[0] !== 0 || pieChartData[1] !== 0 ?  */}

      {/* : null} */}

      {!loading && !(porcentMerval || porcentCrypto || porcentAcciones) ? (
        <Flex
          width="100%"
          justifyContent="center"
          direction="column"
          alignItems="center"
          gap="16px"
          padding="80px 40px"
          borderWidth="1px"
          borderColor="#eaeaea"
          borderRadius="20px"
        >
          <Icon fontSize="2xl" color="#4319ff">
            <FaCoins />
          </Icon>
          <Flex
            direction="column"
            justifyContent="center"
            alignItems="center"
            gap="2px"
          >
            <Heading size="sm">No se encontraron activos</Heading>
            <Text size="sm" color="gray.500">
              Todavía no tenes activos en tu cartera
            </Text>
          </Flex>
          <Link to="/dashboard/gestion-activos">
            <Button
              backgroundColor="#4319FF"
              color="white"
              width="fit-content"
              fontSize={"sm"}
              fontWeight={500}
              borderRadius={"full"}
            >
              Agregar activo
            </Button>
          </Link>
        </Flex>
      ) : (
        <Flex
          width="100%"
          justify="center"
          marginBottom="20px"
          flexDirection={isMobile ? "column" : "row"}
        >
          <Flex width={isMobile ? "100%" : "50%"}>
            {!loading && (porcentMerval || porcentCrypto || porcentAcciones) ? (
              <DoughnutChart data={activesCompositionData} />
            ) : (
              <Skeleton
                containerClassName="flex-1"
                width={"265px"}
                height="265px"
                borderRadius={"265px"}
              />
            )}
          </Flex>

          <Flex width={isMobile ? "100%" : "50%"} direction="column">
            {/* {porcentAcciones || porcentCrypto || porcentMerval ? ( */}
              <Flex
                bg={cardColor}
                flexDirection={"column"}
                // boxShadow={cardShadow}
                w="50%"
                p="15px"
                px="20px"
                //mt="15px"
                mx="auto"
                justifyContent="space-between"
                height="100%"
              >
                {porcentAcciones || porcentCrypto || porcentMerval ?
                <>
                <Flex direction="column" py="5px" textAlign="center">
                  <Flex align="center" justify="center">
                    <Box
                      h="8px"
                      w="8px"
                      bg="#6AD2FF"
                      borderRadius="50%"
                      me="4px"
                    />
                    <Text
                      fontSize="xs"
                      color="secondaryGray.600"
                      fontWeight="700"
                    >
                      Cripto
                    </Text>
                  </Flex>
                  <Text fontSize="lg" color={textColor} fontWeight="700">
                    {porcentCrypto.toFixed(2)}%
                  </Text>
                </Flex>

                <VSeparator mx={{ base: "60px", xl: "60px", "2xl": "60px" }} />

                <Flex
                  direction="column"
                  py="5px"
                  textAlign="center"
                  alignContent="flex-start"
                >
                  <Flex align="center" justify="center">
                    <Box
                      h="8px"
                      w="8px"
                      bg="brand.500"
                      borderRadius="50%"
                      me="4px"
                    />
                    <Text
                      fontSize="xs"
                      color="secondaryGray.600"
                      fontWeight="700"
                    >
                      Cedears
                    </Text>
                  </Flex>
                  <Text fontSize="lg" color={textColor} fontWeight="700">
                    {porcentMerval.toFixed(2)}%
                  </Text>
                </Flex>

                <VSeparator mx={{ base: "60px", xl: "60px", "2xl": "60px" }} />

                <Flex
                  direction="column"
                  py="5px"
                  textAlign="center"
                  alignContent="flex-start"
                >
                  <Flex align="center" justify="center">
                    <Box
                      h="8px"
                      w="8px"
                      bg="#82fec8"
                      borderRadius="50%"
                      me="4px"
                    />
                    <Text
                      fontSize="xs"
                      color="secondaryGray.600"
                      fontWeight="700"
                    >
                      Acciones
                    </Text>
                  </Flex>
                  <Text fontSize="lg" color={textColor} fontWeight="700">
                    {porcentAcciones.toFixed(2)}%
                  </Text>
                </Flex>
                </> 
                : 
                <Skeleton count={11} containerClassName="flex-1" width={"200px"} />
                }
              </Flex>
            {/* ) : null} */}
          </Flex>
        </Flex>
      )}
    </Card>
  );
}
