import { Grid, useMediaQuery } from '@chakra-ui/react';
import Skeleton from 'react-loading-skeleton';

const SkeletonLoading = ({rows}) => {
  const [isMobile] = useMediaQuery("(max-width: 768px)");

    const generateSkeletons = () => {
        const arraySkeleton = [];
        const skeletons = []
        for (let i = 0; i < rows; i++){
          skeletons.push(<Skeleton width={"100%"} height={"30px"} />)
        }
        for (let index = 0; index < 10; index++) {
            arraySkeleton.push(
              <Grid templateColumns={`repeat(${rows}, 1fr)`} gap="10px" margin="0 0 20px 0" padding={isMobile ? "" : "0 14px"}>
                {skeletons}
              </Grid>
            )
        }
        return arraySkeleton;
    }

    return(
      generateSkeletons()
    )
}

export default SkeletonLoading