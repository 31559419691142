// Chakra imports
// Chakra imports
import {
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
  Text,
  StatHelpText,
  StatArrow,
  Icon,
  useMediaQuery,
  Box,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
// Custom icons
import React from "react";
import { BsArrowDownShort, BsArrowUpShort } from "react-icons/bs";

export default function Default(props) {
  const { startContent, endContent, name, growth, value, data } = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "secondaryGray.600";
  const [isMobile] = useMediaQuery("(max-width: 768px)");

  return (
    <Card py="15px">
      <Flex
        my="auto"
        h="100%"
        align={isMobile ? "" : { base: "center", xl: "start" }}
        justify={isMobile ? "" : { base: "center", xl: "center" }}
        direction={isMobile ? "column" : "row"}
      >
        <Flex w={"100%"}>
          {startContent}
          <Stat my="auto" ms={startContent ? "18px" : "0px"}>
            <Flex
              direction={isMobile ? "column" : "row"}
              justify={isMobile ? "" : "space-between"}
              align={isMobile ? "" : "flex-end"}
            >
              <Flex direction={"column"}>
                <StatLabel
                  lineHeight="100%"
                  color={textColorSecondary}
                  fontSize={{
                    base: "sm",
                  }}
                >
                  {name}
                </StatLabel>
                <StatNumber
                  color={textColor}
                  fontSize={{
                    base: "2xl",
                  }}
                >
                  {value}
                </StatNumber>
              </Flex>
              {data && !isMobile ? (
                <StatHelpText
                  fontWeight={500}
                  margin={"0 0 4px 0"}
                  display={"flex"}
                  gap="6px"
                >
                  <Icon
                    color={parseFloat(data.porcentaje) < 0 ? "red" : "#1eb87c"}
                    fontSize={"22px"}
                    position={"relative"}
                  >
                    {parseFloat(data.porcentaje) < 0 ? (
                      <BsArrowDownShort />
                    ) : (
                      <BsArrowUpShort />
                    )}
                  </Icon>
                  <Text
                    color={parseFloat(data.porcentaje) < 0 ? "red" : "#1eb87c"}
                    fontWeight={600}
                  >
                    {data.porcentaje.toFixed(3)}%
                  </Text>
                  <Text color={"gray.900"}>
                    ({data.totalInversionesAnterior})
                  </Text>
                </StatHelpText>
              ) : null}
            </Flex>

            {growth ? (
              <Flex align="center">
                <Text color="green.500" fontSize="xs" fontWeight="700" me="5px">
                  {growth}
                </Text>
                <Text color="secondaryGray.600" fontSize="xs" fontWeight="400">
                  desde el último mes
                </Text>
              </Flex>
            ) : null}
          </Stat>
        </Flex>
        <Flex>
          {data && isMobile ? (
            <Flex
              fontWeight={500}
              margin={"0 0 4px 0"}
              display={"flex"}
              gap="6px"
              fontSize={"14px"}
              borderTop="1px solid"
              borderColor="#eaeaea"
              width="100%"
              padding={"10px 0 0 0"}
            >
              <Icon
                color={parseFloat(data.porcentaje) < 0 ? "red" : "#1eb87c"}
                fontSize={"22px"}
                position={"relative"}
              >
                {parseFloat(data.porcentaje) < 0 ? (
                  <BsArrowDownShort />
                ) : (
                  <BsArrowUpShort />
                )}
              </Icon>
              <Text
                color={parseFloat(data.porcentaje) < 0 ? "red" : "#1eb87c"}
                fontWeight={600}
              >
                {data.porcentaje.toFixed(3)}%
              </Text>
              <Text color={"gray.900"}>({data.totalInversionesAnterior})</Text>
            </Flex>
          ) : null}
        </Flex>
        <Flex ms="auto" w="max-content">
          {endContent}
        </Flex>
      </Flex>
    </Card>
  );
}
